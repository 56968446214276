@font-face {
  font-family: 'SharpGrotesk';
  src: url('SharpGroteskMedium/eot/SharpGroteskMedium.eot');
  src: url('SharpGroteskMedium/woff2/SharpGroteskMedium.woff2') format('woff2'),
    url('SharpGroteskMedium/woff/SharpGroteskMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Campton';
  src: url('Campton/web/Campton-Regular.eot'),
    url('Campton/web/Campton-Regular.woff2') format('woff2'),
    url('Campton/web/Campton-Regular.woff') format('woff'),
    url('Campton/otf/Campton-Regular.otf') format('opentype'),
    url('Campton/ttf/CamptonTT-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Campton';
  src: url('Campton/web/Campton-RegularItalic.eot'),
    url('Campton/web/Campton-RegularItalic.woff2') format('woff2'),
    url('Campton/web/Campton-RegularItalic.woff') format('woff'),
    url('Campton/otf/Campton-RegularItalic.otf') format('opentype'),
    url('Campton/ttf/CamptonTT-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Campton';
  src: url('Campton/web/Campton-Medium.eot'),
    url('Campton/web/Campton-Medium.woff2') format('woff2'),
    url('Campton/web/Campton-Medium.woff') format('woff'),
    url('Campton/otf/Campton-Medium.otf') format('opentype'),
    url('Campton/ttf/CamptonTT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Campton';
  src: url('Campton/web/Campton-SemiBold.eot'),
    url('Campton/web/Campton-SemiBold.woff2') format('woff2'),
    url('Campton/web/Campton-SemiBold.woff') format('woff'),
    url('Campton/otf/Campton-SemiBold.otf') format('opentype'),
    url('Campton/ttf/CamptonTT-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
